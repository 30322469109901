.icon {
    margin-right: 42px;
}

.menuIcon {
    display: none !important;
}

@media screen and (max-width: 920px) {
    .link {
        display: none;
    }

    .icon {
        margin-right: 0px;
    }

    .menuIcon {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}