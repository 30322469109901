.link {
    margin-right: 50px;
    font-weight: 600;
    color: white;
    white-space: nowrap;
}

.mailLink {
    margin-right: 50px;
    font-weight: 600;
    color: rgb(225, 163, 75);
}

.flexLink {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 1100px) {
    .link {
        margin-right: 40px;
        font-size: 14px;
    }

    .mailLink {
        margin-right: 40px;
        font-size: 14px;
    }

    .flexLink {
        margin-right: 40px;
    }
}

@media screen and (max-width: 768px) {
    .flexLink {
        display: none;
    }

    .link {
        margin-right: 0;
        padding: 5px 0;
    }

    .mailLink {
        margin-right: 0;
        padding: 5px 0;
    }
}
