.activeRoute {
    path {
        fill: #E1A34B
    }
}

.inActiveRoute {
    path {
        fill: #3D3D3D
    }
}