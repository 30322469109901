.tableWrapper {
  max-width: 100%;
  position: relative;
  border-collapse: collapse;

  thead {
    tr {
      th {
        padding: 10px 0px;
        font-size: 14px;
        line-height: 20px;

        &:nth-child(1) {
          padding-left: 20px;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #e3e3e3;

      td {
        padding: 3px 20px 3px 0px;

        &:nth-child(1) {
          padding-left: 20px;
          padding-right: 0px;
          width: 46px;
        }
      }
    }
  }
}
