.fileSave {
    border-radius: 25px;
    height: 35px;
    width: 100%;
    z-index: 2;
    position: absolute;
    left: 0;
    border: none;
}

.fileSave[type=file] {
    opacity: 0;
}