@import "config/styles/colors.scss";

.link {
  position: relative;
  color: $primary_black;
  margin: 0 32px;
  padding: 8px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 2em;
}

.link::after {
  background-color: $accent;
  bottom: 2px;
  content: '';
  height: 2px;
  left: 0px;
  position: absolute;
  transition: width .2s ease-in-out;
  visibility: hidden;
  width: 0;
}

.link:hover {
  color: $accent;
}

.link:hover::after {
  visibility: visible;
  width: 100%;
}

.activeLink {
  color: $accent;
}

.link.activeLink::after {
  visibility: visible;
  width: 100%;
}

@media screen and (max-width: 1024px){
  .link {
      margin: 0 16px;
  }
}

@media screen and (max-width: 920px) {
  .loginLink {
    display: none !important;
  }

  .link {
      margin: 0;
  }
}