@import "config/styles/colors.scss";

.link {
    display: none;
    margin: 0 16px;
}

.mobileNavActive>.link {
    display: block;
}

.mobileNav.mobileNavActive {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
}

.mobileNav {
    display: flex;
    align-items: flex-start;
    flex: 1 1 auto;
    background-color: #fff;
    bottom: 0;
    flex-direction: column;
    height: calc(100% - 84px);
    left: 0;
    padding: 45px 10%;
    position: fixed;
    transform: translateY(8px);
    transition: transform .25s ease-in-out, opacity .25s ease-in-out;
    visibility: hidden;
    width: 100%;
}
