.toastContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 0 20px 0 5px;
  
    svg {
      margin-right: 10px;
    }
  
    .svgSuccess {
      color: rgb(31, 181, 111);
    }
    .svgError {
      color: rgb(255, 255, 255);
    }
  }